<script lang="ts" setup>
const emit = defineEmits<{ (e: "loadMore"): void }>();

const props = defineProps<{
	canLoadMore?: boolean;
}>();

const scrollComponent = ref();

const handleScroll = () => {
	if (!scrollComponent.value) {
		return;
	}
	const element = scrollComponent.value as HTMLDivElement;
	if (element?.getBoundingClientRect()?.bottom < window.innerHeight) {
		emit("loadMore");
	}
};

const unwatchLoadMore = watch(
	() => props.canLoadMore,
	(val) => {
		if (val) {
			handleScroll();
			window.addEventListener("scroll", handleScroll);
			return;
		}

		window.removeEventListener("scroll", handleScroll);
	}
);

onMounted(() => {
	nextTick(() => {
		if (props.canLoadMore) {
			handleScroll();
			window.addEventListener("scroll", handleScroll);
		}
	});
});

onUnmounted(() => {
	unwatchLoadMore();
	window.removeEventListener("scroll", handleScroll);
});
</script>

<template>
	<div ref="scrollComponent">
		<slot />
	</div>
</template>
